import { graphql, useStaticQuery } from 'gatsby';
import { type FC } from 'react';

import { DEFAULT_MENUS } from 'src/constants';
import { useIsMobile, useIsMounted } from 'src/utils';
import { Mobile } from './mobile';
import { Pc } from './pc';

type Props = {
  className?: string;
};

const query = graphql`
  query ApplyUrl {
    contentfulHome(slug: { eq: "home" }) {
      aboutFoundationApplyUrl
    }
  }
`;

const useMenuList = () => {
  const { contentfulHome } = useStaticQuery<Queries.ApplyUrlQuery>(query);
  const { aboutFoundationApplyUrl } = contentfulHome ?? {};
  const menuList = DEFAULT_MENUS.map((menu) => {
    if (menu.name === 'foundation-apply-url') {
      return {
        ...menu,
        href: aboutFoundationApplyUrl,
      };
    }
    return menu;
  });
  return { menuList };
};

export const Navigation: FC<Props> = ({ className }) => {
  const isMobile = useIsMobile();
  const isMounted = useIsMounted();
  const { menuList } = useMenuList();

  return (
    <div className={className || ''}>
      {isMounted && (
        <>
          {isMobile && <Mobile menuList={menuList} />}
          {!isMobile && <Pc menuList={menuList} />}
        </>
      )}
    </div>
  );
};

export default Navigation;
