import styled from '@emotion/styled';
import { type FC } from 'react';

import { Button } from 'src/components/common/atoms';
import { media } from 'src/styles';

type Props = {
  className?: string;
  onClick: () => void;
  isPressed: boolean;
  targetId?: string;
  ariaLabel?: string;
  color?: string;
};

export const MenuButton: FC<Props> = ({
  className,
  onClick,
  isPressed,
  targetId,
  ariaLabel = 'main menu',
  color,
}) => (
  <StyledMenuButton
    className={className}
    onClick={onClick}
    aria-expanded={isPressed}
    aria-pressed={isPressed}
    aria-label={ariaLabel}
    aria-controls={targetId}
  >
    <Line backgroundColor={color || undefined} />
    <Line backgroundColor={color || undefined} />
    <Line backgroundColor={color || undefined} />
  </StyledMenuButton>
);

type LineProps = {
  backgroundColor?: string;
};
const Line = styled.i<LineProps>`
  display: inline-block;
  box-sizing: border-box;
  transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 100%;
  background-color: ${(props: LineProps) => props.backgroundColor || 'black'};
`;

const StyledMenuButton = styled(Button)`
  --button-height: 19px;
  --button-width: 23px;
  --border-size: 1px;
  display: inline-block;
  box-sizing: border-box;
  transition: 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
  width: var(--button-width);
  height: var(--button-height);
  display: flex;
  align-items: center;
  border: none;

  &:focus {
    outline: none;
  }

  &:hover,
  &.focus-visible {
    transform: scale(1.1);
  }

  &:focus-visible {
    transform: scale(1.1);
  }

  ${Line} {
    position: absolute;
    left: 0;
    height: var(--border-size);
  }

  &[aria-pressed='false'] {
    ${Line} {
      &:nth-of-type(1) {
        top: 5%;
      }
      &:nth-of-type(2) {
        top: calc((var(--button-height) / 2) - (var(--border-size) / 2));
      }
      &:nth-of-type(3) {
        bottom: 5%;
      }
    }
  }

  &[aria-pressed='true'] {
    ${Line} {
      &:nth-of-type(1) {
        top: 0;
        transform: translateY(
            calc(calc(var(--button-height) / 2) - calc(var(--border-size) / 2))
          )
          rotate(-45deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        bottom: 0;
        transform: translateY(
            calc(
              (calc(var(--button-height) / 2) - calc(var(--border-size) / 2)) *
                -1
            )
          )
          rotate(45deg);
      }
    }
    &:hover {
      ${Line} {
        &:nth-of-type(1) {
          transform: translateY(
              calc(
                calc(var(--button-height) / 2) - calc(var(--border-size) / 2)
              )
            )
            rotate(-215deg);
        }

        &:nth-of-type(3) {
          transform: translateY(
              calc(
                (calc(var(--button-height) / 2) - calc(var(--border-size) / 2)) *
                  -1
              )
            )
            rotate(215deg);
        }
      }
    }
  }

  ${media.greaterThanIphoneVertical} {
    --button-height: 26px;
    --button-width: 34px;
  }
`;
