import { useMediaQuery } from '@react-hook/media-query';

import { theme } from 'src/styles';

export const useIsMobile = (): boolean =>
  useMediaQuery(`(max-width: ${theme.breakpoints.siteBreakpoint - 1}px)`);

export const useIsSp = (): boolean =>
  useMediaQuery(`(max-width: ${theme.breakpoints.ipadVertical - 1}px)`);

export default useIsMobile;
