import { type FC } from 'react';
// import 'focus-visible';
import { Global } from '@emotion/react';
import { PageProps } from 'gatsby';

import { ProviderManager, SitePage } from 'src/components/common';
import { GlobalStyle } from 'src/styles';
import { PageContextType } from 'types/custom-types';

type Props = PageProps<unknown, PageContextType>;

const Layouts: FC<Props> = ({ location, children }) => {
  const { pathname } = location;
  return (
    <>
      <Global styles={GlobalStyle} />
      <ProviderManager pathname={pathname}>
        <SitePage>{children}</SitePage>
      </ProviderManager>
    </>
  );
};

export default Layouts;
