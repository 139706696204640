import { graphql, useStaticQuery } from 'gatsby';
import { Fragment, useEffect, type FC } from 'react';

import { useSlug } from 'src/utils';

type MetaProps = JSX.IntrinsicElements['meta'];
type Props = {
  title?: string | null;
  description?: string | null;
  meta?: MetaProps[];
  image?: string | null;
  imageWidth?: number | null;
  imageHeight?: number | null;
};

const query = graphql`
  query GetSeo {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        twitter
        facebookAppId
        banner
      }
    }
  }
`;

function useAddViewportFitCover() {
  useEffect(() => {
    // NOTE:
    // viewport-fit=coverを追加する。
    // viewportのmetaは.caches/default-html.jsに直接書かれているので、
    // 上書きできないため、ここで無理やり上書きする。
    const viewportMeta = document.head.querySelector<HTMLMetaElement>(
      'meta[name="viewport"]'
    );
    if (
      viewportMeta &&
      !viewportMeta.classList.contains('added-viewport-fit')
    ) {
      const defaultViewportContent = viewportMeta.getAttribute('content');
      if (defaultViewportContent) {
        viewportMeta.setAttribute(
          'content',
          `${defaultViewportContent}, viewport-fit=cover`
        );
        viewportMeta.classList.add('added-viewport-fit');
      }
    }
  }, []);
}

export const SEO: FC<Props> = ({
  title,
  description,
  meta,
  image,
  imageWidth = 1200,
  imageHeight = 630,
}) => {
  const { pathname } = useSlug();
  const { site } = useStaticQuery<Queries.GetSeoQuery>(query);
  const defaultTitle = site?.siteMetadata?.title || '';
  const pageTitle = title || defaultTitle || '';
  const defaultDescription = site?.siteMetadata?.description
    ? site.siteMetadata.description
    : '';
  const metaDescription =
    description?.replace(/\r?\n/g, '')?.substr(0, 450) ||
    defaultDescription ||
    '';
  const siteUrl = site?.siteMetadata?.siteUrl || '';
  const url = `${siteUrl}${pathname}`;
  const defaultBanner = site?.siteMetadata?.banner || '';
  const seoImage = image ? `${siteUrl}${image}` : `${siteUrl}/${defaultBanner}`;

  useAddViewportFitCover();

  const defaultMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:url`,
      content: url,
    },
    {
      property: `og:title`,
      content: pageTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: seoImage,
    },
    {
      property: `og:image:width`,
      content: imageWidth,
    },
    {
      property: `og:image:height`,
      content: imageHeight,
    },
    {
      property: `fb:app_id`,
      content: site?.siteMetadata?.facebookAppId,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:creator`,
      content: site?.siteMetadata?.author,
    },
    {
      name: `twitter:title`,
      content: pageTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: seoImage,
    },
  ] as MetaProps[];

  const schemaOrgJSONLD: any = [
    {
      '@context': 'http://schema.org',
      '@type': 'Corporation',
      '@id': siteUrl,
      url: siteUrl,
      name: defaultTitle,
      image: {
        '@type': 'ImageObject',
        url: `${siteUrl}/${defaultBanner}`,
      },
    },
  ];

  const preconnects = [
    'https://www.google-analytics.com',
    'https://www.googletagmanager.com',
  ];

  return (
    <>
      <title>{title ? `${title} - ${defaultTitle}` : defaultTitle}</title>
      {defaultMeta.map((meta, index) => (
        <Fragment key={`meta-${index}`}>
          {meta.name && <meta name={meta.name} content={meta.content} />}
          {meta.property && (
            <meta property={meta.property} content={meta.content} />
          )}
        </Fragment>
      ))}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      {preconnects.map((preconnect, index) => (
        <link
          key={`preconnect-${index}`}
          rel="preconnect"
          crossOrigin="anonymous"
          href={preconnect}
        />
      ))}
    </>
  );
};

export default SEO;
