import { type FC } from 'react';

import {
  MenuList,
  type Props as MenuListProps,
} from 'src/components/common/site-header/navigation/menu-list';

type Props = {
  className?: string;
  menuList: MenuListProps['menuList'];
};

export const Pc: FC<Props> = ({ className, menuList }) => (
  <nav role="navigation" className={className ?? ''}>
    <MenuList menuList={menuList} />
  </nav>
);

export default Pc;
