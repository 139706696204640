import styled from '@emotion/styled';
import { type FC } from 'react';

import bgImgPc from 'src/images/bg_footer_pc.png';
import bgImgSp from 'src/images/bg_footer_sp.png';
import { media, theme } from 'src/styles';
import { Content } from './content';

type Props = {
  className?: string;
};

export const SiteFooter: FC<Props> = ({ className }) => (
  <div className={className ?? ''}>
    <Content />
    <ImgWrapper>
      <picture>
        <source
          srcSet={bgImgPc}
          media={`(min-width: ${theme.breakpoints.ipadVertical + 1}px)`}
        />
        <img src={bgImgSp} alt="" />
      </picture>
    </ImgWrapper>
  </div>
);

const ImgWrapper = styled.div`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  img {
    width: 100%;
  }
  ${media.siteBreakpointOrMore} {
    position: relative;
    z-index: -1;
    margin-top: -60px;
  }
`;

export default SiteFooter;
