import styled from '@emotion/styled';
import { type FC } from 'react';

import { ScreenReaderText } from 'src/components/common';
import { Anchor } from 'src/components/common/atoms';
import LogoPcSVG from 'src/images/logo_pc.svg';
import LogoSpSVG from 'src/images/logo_sp.svg';
import { useIsMobile, useIsMounted, useSlug } from 'src/utils';

type Props = {
  className?: string;
};

export const Title: FC<Props> = ({ className }) => {
  const { isHome } = useSlug();
  const isMobile = useIsMobile();
  const isMounted = useIsMounted();
  return (
    <Wrapper className={`${className || ''}`} as={isHome ? 'h1' : 'p'}>
      <StyledAnchor to={`/`} lang="en">
        <ScreenReaderText>coconogacco foundation</ScreenReaderText>
        {isMounted && isMobile && <LogoSpSVG />}
        {isMounted && !isMobile && <LogoPcSVG />}
      </StyledAnchor>
    </Wrapper>
  );
};

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  display: block;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const Wrapper = styled.h1``;

export default Title;
