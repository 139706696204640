import styled from '@emotion/styled';
import { useEffect, useState, type FC, type ReactNode } from 'react';

import { media, theme } from 'src/styles';
// NOTE:
// commonから読み込んだSiteHeader, SiteFooterにstyled当てるとエラーになるので、
// 直接読み込む
import { SiteFooter } from 'src/components/common/site-footer';
import { SiteHeader } from 'src/components/common/site-header';
import bgHeaderImgPc from 'src/images/bg_header_pc.png';
import bgHeaderImgSp from 'src/images/bg_header_sp.png';

type Props = {
  className?: string;
  children: ReactNode;
};

const useIsShown = () => {
  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsShown(true);
    }, 600);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  return isShown;
};

export const SitePage: FC<Props> = ({ className, children }) => {
  const isShown = useIsShown();

  return (
    <Wrapper className={`${className ?? ''} ${isShown ? 'is-shown' : ''}`}>
      <ImgWrapper>
        <picture>
          <source
            srcSet={bgHeaderImgPc}
            media={`(min-width: ${theme.breakpoints.ipadVertical + 1}px)`}
          />
          <img src={bgHeaderImgSp} alt="" loading="eager" />
        </picture>
      </ImgWrapper>
      <StyledSiteHeader />
      <Main role="main" id="main">
        {children}
      </Main>
      <StyledSiteFooter />
    </Wrapper>
  );
};

const ImgWrapper = styled.div`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  img {
    width: 100%;
  }
`;

const StyledSiteHeader = styled(SiteHeader)`
  position: sticky;
  z-index: 1000;
  top: 20px;
  ${media.siteBreakpointOrMore} {
    top: 20px;
    margin-top: -70px;
  }
`;

const Main = styled.main`
  flex-grow: 1;
  /* NOTE:
  mainの子要素にdisplay:flexがあり高さが伸びないので、
  ここでもdisplay:flexして、高さを揃える。
   */
  display: flex;
  margin-top: 50px;
  ${media.siteBreakpointOrMore} {
    margin-top: 125px;
  }
`;

const StyledSiteFooter = styled(SiteFooter)`
  margin-top: ${({ theme }) => theme.structure.section.margin.vertical.sp}px;
  ${media.siteBreakpointOrMore} {
    margin-top: ${({ theme }) => theme.structure.section.margin.vertical.pc}px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: ${({ theme }) => theme.maxWidth}px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  font-family: ${({ theme }) => theme.font.family.japanese};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-feature-settings: 'palt';
  letter-spacing: ${({ theme }) => theme.letterSpacing};
  line-height: ${({ theme }) => theme.lineHeight};
  padding-left: ${({ theme }) => theme.structure.main.margin.horizontal.sp}px;
  padding-right: ${({ theme }) => theme.structure.main.margin.horizontal.sp}px;
  opacity: 0;
  transition: opacity 0.2s linear;
  &.is-shown {
    opacity: 1;
  }
  ${media.siteBreakpointOrMore} {
    font-size: ${({ theme }) => theme.font.size.medium};
    padding-left: ${({ theme }) => theme.structure.main.margin.horizontal.pc}px;
    padding-right: ${({ theme }) =>
      theme.structure.main.margin.horizontal.pc}px;
  }
`;

export default SitePage;
