import { useContext } from 'react';
import type {
  DispatchContextType,
  MenuToggleContextType,
} from 'src/contexts/menu-toggle-context';
import {
  MenuToggleContext,
  MenuToggleDispatchContext,
} from 'src/contexts/menu-toggle-context';

export const useMenuToggle = (): MenuToggleContextType =>
  useContext(MenuToggleContext);

export const useMenuToggleDispatch = (): DispatchContextType =>
  useContext(MenuToggleDispatchContext);

export default useMenuToggle;
