import styled from '@emotion/styled';
import { type FC, type ReactNode } from 'react';

import { media } from 'src/styles';
import type { Props as RichTextProps } from './rich-text-renderer';
import { RichTextRenderer } from './rich-text-renderer';

type Props = {
  className?: string;
  document: RichTextProps['document'];
  headerChildren?: ReactNode;
  footerChildren?: ReactNode;
};

export const RichText: FC<Props> = ({
  className,
  document,
  headerChildren,
  footerChildren,
}) => (
  <Wrapper className={className ?? ''}>
    {headerChildren && headerChildren}
    <RichTextRenderer document={document} />
    {footerChildren && footerChildren}
  </Wrapper>
);

const Wrapper = styled.div`
  * {
    & + :not(li):not(h3):not(h4):not(h5):not(h6) {
      margin-top: 1em;
    }
    & + li {
      margin-top: 1em;
    }
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: ${({ theme }) =>
        theme.structure.contentBlock.margin.vertical.sp}px;
    }
    &.has-black-button + h3 {
      margin-top: ${({ theme }) =>
        theme.structure.section.margin.vertical.sp}px;
    }
    &:empty:not(br) {
      display: none;
    }
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: ${({ theme }) => theme.font.size.mediumLarge};
  }

  ${media.siteBreakpointOrMore} {
    * {
      & + h3,
      & + h4,
      & + h5,
      & + h6 {
        margin-top: ${({ theme }) =>
          theme.structure.contentBlock.margin.vertical.pc}px;
      }
      &.has-black-button + h3 {
        margin-top: ${({ theme }) =>
          theme.structure.section.margin.vertical.pc}px;
      }
    }

    h3,
    h4,
    h5,
    h6 {
      font-size: ${({ theme }) => theme.font.size.large};
    }

    .has-black-button {
      margin-top: ${({ theme }) =>
        theme.structure.contentBlock.margin.vertical.pc}px;
    }
  }
`;

export default RichText;
