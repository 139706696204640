import styled from '@emotion/styled';
import {
  GatsbyImage,
  getImage,
  type GatsbyImageProps,
} from 'gatsby-plugin-image';
import { useCallback, useMemo, useState, type FC } from 'react';

export type Props = Omit<GatsbyImageProps, 'image' | 'onLoad'> & {
  className?: string;
  localFile?: {
    childImageSharp: Queries.Maybe<Pick<Queries.ImageSharp, 'gatsbyImageData'>>;
  };
  onLoad?: () => void;
};

const useAspectRatioClass = (image?: GatsbyImageProps['image'] | null) =>
  useMemo(() => {
    if (!image) {
      return null;
    }
    const aspectRatio = image.width / image.height;
    return aspectRatio > 1
      ? 'horizontal'
      : aspectRatio === 1
      ? 'square'
      : 'vertical';
  }, [image]);

type GetImageArgProps = Parameters<typeof getImage>[0];
const getImageData = (localFile: Props['localFile']) => {
  if (!localFile) {
    return null;
  }
  // NOTE:
  // getImageの引数は、FileNodeなのだが、
  // gatsby-plugin-image側で定義されているFileNode内のNodeのid、parentなどが必須になっているので、無視して渡すようにする。
  const { childImageSharp } = localFile;
  return getImage({ childImageSharp } as GetImageArgProps);
};

export const Sharp: FC<Props> = ({
  className,
  loading = 'lazy',
  onLoad,
  localFile,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(false);
  const onImgLoad = useCallback(() => {
    // NOTE:
    // gatsby imageのonLoadは2回目（一度読み込まれた画像で、ページ遷移後にもう一度表示する画像）は動作しない可能性がある？
    // 要検証
    setLoaded(true);
    onLoad && onLoad();
  }, [onLoad]);

  const image = getImageData(localFile);
  const aspectRatioClass = useAspectRatioClass(image);
  if (!localFile || !image) {
    return null;
  }
  return (
    <Wrapper
      className={`${className || ''} image-type-sharp ${
        loaded ? 'loaded' : ''
      } ${aspectRatioClass ?? ''}`}
    >
      <GatsbyImage
        image={image}
        loading={loading}
        onLoad={onImgLoad}
        {...rest}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  &:not(.loaded) {
    /* outline: 1px solid black;
  background-color: white;
  > div {
    opacity: 0;
  } */
  }
`;

export default Sharp;
