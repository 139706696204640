import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { graphql, useStaticQuery } from 'gatsby';
import { type FC } from 'react';

import { Instagram } from 'src/components/common/instagram';
import { media } from 'src/styles';

type Props = {
  className?: string;
};

type StaticQueryProps = Queries.GetFooterQuery;

const staticQuery = graphql`
  query GetFooter {
    contentfulHome(slug: { eq: "home" }) {
      contactHref
      instagramHref
    }
  }
`;

export const Content: FC<Props> = ({ className }) => {
  const { contentfulHome } = useStaticQuery<StaticQueryProps>(staticQuery);
  return (
    <Wrapper className={className ?? ''}>
      <Contact>
        {contentfulHome?.contactHref && (
          <>
            <a
              href={contentfulHome.contactHref}
              target="_blank"
              rel="noreferrer"
            >
              contact（お問い合わせ）
            </a>
            <br />
            運営：coconogacco
          </>
        )}
      </Contact>
      <StyledInstagram href={contentfulHome?.instagramHref} />
      <Copyright>
        &copy;{dayjs().format('YYYY')} co&co.Ltd. All Rights Reserved.
      </Copyright>
    </Wrapper>
  );
};

const Contact = styled.p`
  a {
    text-decoration: none;
    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }
  ${media.siteBreakpointOrMore} {
    font-size: ${({ theme }) => theme.font.size.large};
    flex: 1;
  }
`;

const StyledInstagram = styled(Instagram)`
  font-size: 2em;
  ${media.lessThanSiteBreakpoint} {
    display: none;
  }
`;

const Copyright = styled.p`
  font-size: ${({ theme }) => theme.font.size.small};
  ${media.lessThanSiteBreakpoint} {
    margin-top: 2em;
  }
  ${media.siteBreakpointOrMore} {
    flex: 1;
    text-align: right;
  }
`;

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.font.family.mix};
  ${media.siteBreakpointOrMore} {
    display: flex;
    justify-content: space-between;
  }
`;

export default Content;
