import {
  type NodeRenderer,
  type Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import type {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import {
  useCallback,
  useMemo,
  type FC,
  type ReactElement,
  type ReactNode,
} from 'react';

import { Anchor } from 'src/components/common/atoms';

// NOTE:
// referensesが必須になっているが、
// 実際はreferensesが無いこともあるので、
// 型操作を行う。
//
type RichTextDocumenType =
  RenderRichTextData<ContentfulRichTextGatsbyReference>;
type CustomRichTextDocumentType = {
  raw?: string | null;
  readonly references: Queries.Maybe<
    ReadonlyArray<Queries.Maybe<ContentfulRichTextGatsbyReference>>
  >;
};
export type Props = {
  children?: never;
  document: Partial<CustomRichTextDocumentType>;
};

const isReactElement = (arg: any): arg is ReactElement =>
  arg?.type !== undefined;

const getHasBold = (children: ReactNode) => {
  let hasBold = false;
  if (children && Array.isArray(children)) {
    const first = children[0];
    if (first && isReactElement(first) && first.type === 'b') {
      hasBold = true;
    }
  }
  return hasBold;
};
// https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-source-contentful/CHANGELOG.md#400-next0-2020-11-09
const useOptions = () => {
  const linkResolver = useCallback<NodeRenderer>((node, children) => {
    const { uri } = node.data;
    const hasBold = getHasBold(children);
    return (
      <Anchor to={uri} className={hasBold ? 'black-button' : undefined}>
        {children}
      </Anchor>
    );
  }, []);

  const assetLinkResolver = useCallback<NodeRenderer>((node, children) => {
    const { target } = node.data;
    const href = target?.file?.url as string | undefined;
    if (!href) {
      return;
    }
    const hasBold = getHasBold(children);
    return (
      <a
        href={`https:${href}`}
        className={hasBold ? 'black-button' : undefined}
        target="_blank"
        rel="noopenner noreferrer"
      >
        {children}
      </a>
    );
  }, []);

  const customParagraph = useCallback<NodeRenderer>((node, children) => {
    let hasBlackButton = false;
    if (children && Array.isArray(children)) {
      hasBlackButton = children?.some((child) => {
        if (isReactElement(child)) {
          return child?.props?.className === 'black-button';
        }
        return false;
      });
    }
    return (
      <p className={hasBlackButton ? 'has-black-button' : undefined}>
        {children}
      </p>
    );
  }, []);

  return useMemo<Options>(
    () => ({
      renderNode: {
        [INLINES.HYPERLINK]: linkResolver,
        [INLINES.ASSET_HYPERLINK]: assetLinkResolver,
        [BLOCKS.PARAGRAPH]: customParagraph,
      },
      // NOTE:
      // 改行を<br>に変換
      // https://github.com/contentful/rich-text/issues/96
      renderText: (text) =>
        text
          .split('\n')
          .flatMap((text, i) => [
            i > 0 && <br key={`line-break-${i}`} />,
            text,
          ]),
    }),
    [customParagraph, linkResolver, assetLinkResolver]
  );
};
export const RichTextRenderer: FC<Props> = ({ document }) => {
  const options = useOptions();
  const { raw, references } = document;
  if (!raw) {
    return null;
  }
  // NOTE:
  // 強制的に、referencesのreadonlyを外す。
  const customDocument = references
    ? ({ raw, references } as RichTextDocumenType)
    : ({ raw, references: [] } as RichTextDocumenType);
  return <>{renderRichText(customDocument, options)}</>;
};

export default RichTextRenderer;
