export const DEFAULT_MENUS = [
  {
    name: 'foundation-apply-url',
    label: '参加する',
    href: '',
  },
  {
    name: 'coconogacco',
    label: 'coconogacco HP',
    href: '#coconogacco',
  },
];
