import { ThemeProvider } from '@emotion/react';
import { useReducer, type FC, type ReactNode } from 'react';

import {
  initialValue as menuToggleInitialValuse,
  MenuToggleContext,
  MenuToggleDispatchContext,
  MenuToggleReducer,
} from 'src/contexts/menu-toggle-context';
import { SlugContext } from 'src/contexts/slug-context';
import { theme as styleTheme } from 'src/styles';

type Props = {
  pathname: string;
  children: ReactNode;
};

function getSlug({ pathname }: { pathname: string }) {
  if (pathname === '/') {
    return 'home';
  } else {
    return pathname.replace(/^\/?([^/]+)\/?$/, '$1');
  }
}

export const ProviderManager: FC<Props> = ({ pathname, children }) => {
  const isHome = pathname === '/';

  // slug
  const slug = getSlug({ pathname });

  const [menuToggleState, menuToggleDispatch] = useReducer(
    MenuToggleReducer,
    menuToggleInitialValuse
  );

  return (
    <ThemeProvider theme={styleTheme}>
      <SlugContext.Provider value={{ slug, pathname, isHome }}>
        <MenuToggleContext.Provider value={menuToggleState}>
          <MenuToggleDispatchContext.Provider
            value={{ dispatch: menuToggleDispatch }}
          >
            {children}
          </MenuToggleDispatchContext.Provider>
        </MenuToggleContext.Provider>
      </SlugContext.Provider>
    </ThemeProvider>
  );
};

export default ProviderManager;
