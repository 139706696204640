import { createContext } from 'react';

export const initialValue = {
  slug: '',
  pathname: '',
  isHome: true,
};

export type SlugContextType = typeof initialValue;

export const SlugContext = createContext<SlugContextType>(initialValue);

export default SlugContext;
