import { css } from '@emotion/react';
import reset from 'emotion-reset';
import { theme } from './theme';

export const GlobalStyle = css`
  ${reset}
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
  ::selection {
    background: ${theme.colors.lightGray};
  }

  html {
    font-size: 62.5%;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: ${theme.font.family.japanese};
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  button,
  input,
  select,
  textarea {
    appearance: none;
    border-radius: 0;
    background-color: ${theme.colors.bg};
    line-height: ${theme.lineHeight};
    font-family: inherit;
    font-size: inherit;
    border: 1px solid black;
  }

  select::-ms-expand {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: underline;
    &:hover,
    &.focus-visible {
      text-decoration: none;
    }
  }

  a,
  button,
  input,
  select,
  textarea {
    &:focus {
      outline: none;
    }
    &.focus-visible {
      outline: 1px solid black;
    }
    &:focus-visible {
      outline: 1px solid black;
    }
  }

  img,
  svg {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  ul,
  ol {
    list-style: initial;
    padding-left: 1.5em;
  }

  .has-black-button {
    text-align: center;
    margin-top: ${theme.structure.contentBlock.margin.vertical.sp}px;
    .black-button {
      font-size: ${theme.font.size.large};
      font-weight: bold;
      line-height: 1;
      padding: 0.4em 0.6em 0.5em 0.7em;
      text-decoration: none;
      color: white;
      background-color: black;
      border: 1px solid black;
      &:hover,
      &:focus-visible {
        color: black;
        background-color: white;
      }
    }
  }
`;

export default GlobalStyle;
