import { type FC } from 'react';
import { Gif } from './gif';
import { Sharp, type Props as SharpProps } from './sharp';

export type Props = SharpProps & {
  localFile?: {
    publicURL?: string | null;
    extension?: string;
  };
};

export const Img: FC<Props> = (props) => {
  const { localFile, alt, className, loading } = props;
  if (localFile?.extension === 'gif' && localFile?.publicURL) {
    return (
      <Gif
        src={localFile.publicURL}
        alt={alt}
        loading={loading}
        className={className}
      />
    );
  } else {
    return <Sharp {...props} />;
  }
};

export default Img;
