import styled from '@emotion/styled';
import { Fragment, type FC } from 'react';

import { Img } from 'src/components/common/atoms';
import { media, theme } from 'src/styles';
import { nonNullableFilter } from 'src/utils';
import { Slick } from './slick';

type Props = {
  className?: string;
  loading?: JSX.IntrinsicElements['img']['loading'];
  readonly images: Queries.Maybe<
    ReadonlyArray<
      Queries.Maybe<
        Pick<Queries.ContentfulAsset, 'id' | 'title'> & {
          readonly localFile: Queries.Maybe<
            Pick<Queries.File, 'publicURL' | 'extension'> & {
              readonly childImageSharp: Queries.Maybe<
                Pick<Queries.ImageSharp, 'gatsbyImageData'>
              >;
            }
          >;
        }
      >
    >
  >;
};

const { breakpoints } = theme;
export const SlideImages: FC<Props> = ({ className, loading, images }) => {
  const nonNullableImages = images?.filter(nonNullableFilter);
  return (
    <Wrapper className={className ?? ''}>
      {nonNullableImages && (
        <Slick>
          {nonNullableImages.map((image) => (
            <Fragment key={image.id}>
              {image?.localFile && (
                <StyledImg
                  localFile={image.localFile}
                  alt={image.title ?? ''}
                  sizes={`(min-width: ${breakpoints.ipadVertical}px calc(100vw * 0.36)), 100vw`}
                  loading={loading ?? 'lazy'}
                />
              )}
            </Fragment>
          ))}
        </Slick>
      )}
    </Wrapper>
  );
};

const StyledImg = styled(Img)`
  ${media.ipadVerticalOrMore} {
    padding-left: 2%;
    padding-right: 2%;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export default SlideImages;
