import styled from '@emotion/styled';
import { type FC } from 'react';

import { Anchor } from 'src/components/common/atoms';
import { media } from 'src/styles';

export type Props = {
  className?: string;
  name: string;
  label: string;
  href?: string | null;
};

export const MenuListItem: FC<Props> = ({ className, label, href }) => (
  <Wrapper className={className || ''}>
    {href && (
      <StyledAnchor to={href} activeClassName={'current'}>
        {label}
      </StyledAnchor>
    )}
  </Wrapper>
);

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  font-weight: bold;
  font-family: ${({ theme }) => theme.font.family.mix};
  font-size: ${({ theme }) => theme.font.size.xxLarge};
  &:hover,
  &:focus {
    text-decoration: none;
  }
  ${media.siteBreakpointOrMore} {
    font-size: ${({ theme }) => theme.font.size.large};
  }
`;

const Wrapper = styled.li`
  ${media.lessThanSiteBreakpoint} {
    text-align: center;
  }
`;

export default MenuListItem;
