import { ComponentProps, type FC } from 'react';

type Props = Pick<ComponentProps<'img'>, 'src' | 'alt' | 'loading'> & {
  className?: string;
};

export const Gif: FC<Props> = ({ className, src, alt, loading = 'lazy' }) => (
  <div className={`${className ?? ''} image-type-gif`}>
    <img src={src} alt={alt ?? ''} loading={loading} />
  </div>
);

export default Gif;
