import '@emotion/react';

export const theme = {
  font: {
    family: {
      japanese:
        '"游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
      english: '"Helvetica Neue", "Helvetica", sans-serif',
      mix:
        '"Helvetica Neue", "Helvetica", "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
    },
    size: {
      xxLarge: '2.0rem',
      xLarge: '1.8rem',
      large: '1.6rem',
      mediumLarge: '1.5rem',
      medium: '1.4rem',
      small: '1.2rem',
    },
  },
  letterSpacing: '0.1em',
  lineHeight: 2.2,
  colors: {
    bg: '#fff',
    font: '#000',
    gray: '#9FA0A0',
    lightGray: '#cecece',
  },
  structure: {
    main: {
      margin: {
        horizontal: {
          sp: 18,
          pc: 24,
        },
        top: {
          sp: 0,
          pc: 0,
        },
        bottom: {
          sp: 0,
          pc: 0,
        },
      },
    },
    contentBlock: {
      margin: {
        vertical: {
          sp: 35,
          pc: 60,
        },
      },
    },
    section: {
      margin: {
        vertical: {
          sp: 60,
          pc: 100,
        },
      },
    },
  },
  breakpoints: {
    iphoneVertical: 375,
    ipadVertical: 768,
    ipadHorizontal: 1024,
    ipadProHorizontal: 1300,
    siteBreakpoint: 1024,
  },
  maxWidth: 1200,
};

type ThemeType = typeof theme;

declare module '@emotion/react' {
  export interface Theme extends ThemeType {}
}

export default theme;
