import styled from '@emotion/styled';
import { Fragment, type FC } from 'react';
import { media } from 'src/styles';
import { MenuListItem, Props as MenuListItemProps } from './menu-list-item';

export type Props = {
  className?: string;
  menuList: MenuListItemProps[];
};

export const MenuList: FC<Props> = ({ className, menuList }) => (
  <Wrapper className={className || ''}>
    {menuList.map((menu, index) => (
      <Fragment key={`header-menu-${menu.name}`}>
        <StyledMenuListItem {...menu} />
      </Fragment>
    ))}
  </Wrapper>
);

const StyledMenuListItem = styled(MenuListItem)`
  ${media.lessThanSiteBreakpoint} {
    & + & {
      margin-top: 30px;
    }
  }
`;

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
  ${media.siteBreakpointOrMore} {
    display: flex;
    gap: 2em;
  }
`;

export default MenuList;
