import { createContext, type Dispatch, type Reducer } from 'react';

export const initialValue = {
  isToggled: true,
};

export type MenuToggleContextType = typeof initialValue;

export const MenuToggleContext =
  createContext<MenuToggleContextType>(initialValue);

type ReducerType = { type: string; payload: MenuToggleContextType };

export type DispatchContextType = {
  dispatch: Dispatch<ReducerType> | null;
};

export const MenuToggleDispatchContext = createContext<DispatchContextType>({
  dispatch: null,
});

export const MenuToggleReducer: Reducer<MenuToggleContextType, ReducerType> = (
  state,
  action
) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    default:
      throw new Error('MenuToggleReducer error');
  }
};

export default MenuToggleContext;
