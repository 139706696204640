import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { useCallback, useEffect, type FC } from 'react';

import { Instagram } from 'src/components/common/instagram';
import { ScreenReaderTextStyle } from 'src/components/common/screen-reader-text';
import {
  MenuList,
  type Props as MenuListProps,
} from 'src/components/common/site-header/navigation/menu-list';
import { media } from 'src/styles';
import { useMenuToggle, useMenuToggleDispatch, useSlug } from 'src/utils';
import { MenuButton } from './menu-button';

type Props = {
  className?: string;
  menuList: MenuListProps['menuList'];
};

type StaticQueryProps = Queries.GetMobileMenuQuery;

const staticQuery = graphql`
  query GetMobileMenu {
    contentfulHome(slug: { eq: "home" }) {
      instagramHref
    }
  }
`;

const useMenuButton = () => {
  const { slug } = useSlug();
  const { isToggled } = useMenuToggle();
  const { dispatch } = useMenuToggleDispatch();
  // const [showMobileMenu, setShowMobileMenu] = useState(false);
  const onClick = useCallback(() => {
    dispatch &&
      dispatch({
        type: 'set',
        payload: { isToggled: !isToggled },
      });
  }, [isToggled, dispatch]);
  useEffect(() => {
    dispatch &&
      dispatch({
        type: 'set',
        payload: { isToggled: false },
      });
  }, [slug, dispatch]);
  return { showMobileMenu: isToggled, onClick };
};

export const Mobile: FC<Props> = ({ className, menuList }) => {
  const { showMobileMenu, onClick } = useMenuButton();
  const { contentfulHome } = useStaticQuery<StaticQueryProps>(staticQuery);
  const menuListId = 'mobile-menu';
  return (
    <Wrapper role="navigation" className={className ?? ''}>
      <StyledMenuButton
        className={showMobileMenu ? 'shown' : ''}
        isPressed={showMobileMenu}
        onClick={onClick}
        targetId={menuListId}
      />
      <MenuWrapper className={showMobileMenu ? 'shown' : ''}>
        <MenuList menuList={menuList} />
        <StyledInstagram href={contentfulHome?.instagramHref} />
      </MenuWrapper>
    </Wrapper>
  );
};

const StyledMenuButton = styled(MenuButton)`
  position: relative;
  z-index: 2;
  &.shown {
    position: fixed;
    top: 20px;
  }
`;

const StyledInstagram = styled(Instagram)`
  font-size: 2.2em;
  ${media.lessThanSiteBreakpoint} {
    margin-top: 60px;
  }
`;

const MenuWrapper = styled.div`
  &:not(.shown) {
    ${ScreenReaderTextStyle}
  }
  &.shown {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Wrapper = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
`;

export default Mobile;
