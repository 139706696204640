import styled from '@emotion/styled';
import { type FC } from 'react';

import { media } from 'src/styles';
import { Navigation } from './navigation';
import { Title } from './title';

type Props = {
  className?: string;
};

export const SiteHeader: FC<Props> = ({ className }) => (
  <Wrapper className={className || ''} role="banner">
    <TextWrapper>
      <StyledTitle />
      <StyledNavigation />
    </TextWrapper>
  </Wrapper>
);

const StyledTitle = styled(Title)`
  ${media.lessThanSiteBreakpoint} {
    max-width: 320px;
    width: 51.2%;
    margin-left: auto;
    margin-right: auto;
  }
  ${media.siteBreakpointOrMore} {
    flex: 0 0 48%;
  }
`;

const StyledNavigation = styled(Navigation)`
  ${media.siteBreakpointOrMore} {
    flex: 0 0 420px;
  }
`;

const TextWrapper = styled.div`
  ${media.siteBreakpointOrMore} {
    display: flex;
    justify-content: space-between;
    height: 40px;
  }
`;

const Wrapper = styled.header``;

export default SiteHeader;
