import styled from '@emotion/styled';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type FC } from 'react';

type Props = {
  className?: string;
  href?: string | null;
};

export const Instagram: FC<Props> = ({ className, href }) => {
  if (!href) {
    return null;
  }
  return (
    <Wrapper className={className} href={href} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faInstagram} />
    </Wrapper>
  );
};

const Wrapper = styled.a`
  line-height: 1;
  &:hover,
  &:focus-visible {
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export default Instagram;
